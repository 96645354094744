export default {
  containerStyles: {
    marginLeft: '30px',
    marginTop: '40px',
    minHeight: '92.5%',

    '@media screen and (max-width: 960px)': {
      marginLeft: '0px',
    },
  },
};
