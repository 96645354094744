export default (theme) => ({
  pubAssetInfoContainer: {
    width: '100%',
    padding: theme.spacing(2),
  },
  pubAssetLabelPart: {
    width: '100%',
    height: '10%',
    position: 'relative',
  },
  pubAssetInfoLabel: {
    color: '#354052',
    fontSize: '18px',
    marginLeft: '20px',
    marginBottom: '20px',
  },
  pubAssetBoxesContainer: {
    marginTop: '0px',
    marginBottom: '20px',
    width: '100%',
    height: '90%',
    '@media screen and (max-width: 1200px)': {
      marginBottom: '20px',
    }
  },
  pubAssetInfoBoxElement: {
    minHeight: 120,
    border: '1px solid #DFE2E5',
    borderRadius: '2.4px',
    backgroundColor: '#FFFFFF',
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
  },
  pubAssetBoxLabelContainer: {
    minHeight: theme.spacing(7),
    padding: theme.spacing(1),
    display: 'inline-flex',
    alignItems: 'center',
  },
  pubAssetBoxLabel: {
    margin: 0,
    textAlign: 'left',
    color: '#91A1B3',
    fontSize: '14px',
    fontWeight: '600',
    display: 'inline-flex',
    alignItems: 'center',
  },
  pubAssetBoxText: {
    display: 'inline-flex',
    justifyContent: 'center',
    alignItems: 'center',
    color: '#354052',
    fontSize: 18,
    padding: theme.spacing(0.5),
    flexGrow: 1,
    '& > span': {
      width: '100%',
      textOverflow: 'ellipsis',
      overflow: 'hidden',
      textAlign: 'center',
    }
  },
  pubAssetValidWrapper: {
    position: 'absolute',
    marginLeft: '5%',
    marginTop: '-5px',
    right: '20px',
    top: '15px',
  },
  assetValid: {
    display: 'inline',
    backgroundColor: '#45B854',
    color: '#ffffff',
    borderRadius: '4px',
    padding: '2px',
    width: '72px',
    height: '26px',
    textAlign: 'center',
    top: '9px',
    position: 'relative',
  },
  assetPending: {
    display: 'inline',
    backgroundColor: '#F7981C',
    color: '#ffffff',
    borderRadius: '4px',
    padding: '2px',
    // width: '72px',
    height: '26px',
    textAlign: 'center',
    top: '9px',
    position: 'relative',
  },
  assetDeclined: {
    display: 'inline',
    backgroundColor: '#ff0000',
    color: '#ffffff',
    borderRadius: '4px',
    padding: '2px',
    width: '72px',
    height: '26px',
    textAlign: 'center',
    top: '9px',
    position: 'relative',
  },
  assetRegistered: {
    display: 'inline',
    backgroundColor: '#3366ff',
    color: '#ffffff',
    borderRadius: '4px',
    padding: '2px',
    width: '72px',
    height: '26px',
    textAlign: 'center',
    top: '9px',
    position: 'relative',
  },
  buttonsContainer: {
    marginTop: '30px',
  },
  approveButton: {
    fontWeight: 600,
    backgroundColor: '#ffeb3b',
    marginLeft: 'auto',
    marginRight: '50px',
    '&:hover': {
      backgroundColor: '#ffeb3b',
    },
  },
  declineButton: {
    color: 'white',
    fontWeight: 600,
    backgroundColor: '#ff0000',
    marginRight: 'auto',
    marginLeft: '50px',
    '&:hover': {
      backgroundColor: '#ff0000',
    },
  },
})
