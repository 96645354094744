import React from 'react';
import styled from 'styled-components';
import NavContainer from './../../smartcomponents/navcontainer';
import { Divider, Typography } from '@material-ui/core';

const GuidelinesContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(2, minmax(250px, 1fr));
  grid-gap: 2rem;
  margin: 2rem;
`;

const GuidelineItem = styled.div`
border: 1px solid #ccc;
  padding: 1.5rem;
  border-radius: 5px;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 1rem;

  @media (min-width: 768px) {
    grid-template-columns: repeat(2, 1fr);
  }

`;

const GuidelineTitle = styled.h3`
  margin: 0;
  font-size: 1.5rem;
  text-align: center;
  grid-column: 1/-1; 

`;

const GuidelineDescription = styled.p`
  margin: 0;
  margin-top: 1rem;
  
`;
const CenteredDiv = styled.div`
display: flex;
justify-content: center;
align-items: center;
margin: 1rem 0;
`;

const StyledLink = styled.a`
  color: #007bff;
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }
`;


const GuidelinesPage = () => {
  const guidelines = [
    {
      id: 1,
      country: 'Austria',

      documents: [

        {
          name: "Anhang V_Information über die Forfaitierung",
          link: "https://drive.google.com/uc?export=download&id=1HFRIkmwA9tTPssSsswsOiMOSe4ms_Km1"
        },
        {
          name: "VORGABEN FÜR DIE MESSUNG UND ÜBERPRÜFUNG DER GARANTIERTEN ENERGIEEINSPARUNG",
          link: "https://drive.google.com/uc?export=download&id=1EbxFQPDlKtKtEnbIXJQQCDJOXZVu72Q0"
        },
        {
          name: "ÖBEEF Finanzieller und Technischer Leitfaden",
          link: "https://drive.google.com/uc?export=download&id=18ntSYKnPecgAI2nEIdY_fhe1TD0N4aNU"
        },
        {
          name: "Anhang IV_Information über Energieleistungverträg...",
          link: "https://drive.google.com/uc?export=download&id=1_dj-NajAfVLW4-BFNDt3U3wwMwR7C3Rm"
        },
        {
          name: "Anhang III_Wartungsvertrag",
          link: "https://drive.google.com/uc?export=download&id=1-NJVIvr92_90kQ8yGC0UduP8o-t3G-7s"
        },
        {
          name: "Anhang II_Forfaitierungsvertrag",
          link: "https://drive.google.com/uc?export=download&id=11vrx17lnDaceHg78wD998So-LOSj6bBQ"
        },
        {
          name: "Annex 25 Energy Performance Contract Plus general…",
          link: "https://drive.google.com/uc?export=download&id=1zSmIfBu7-SbdDWqQI30gHQVUNwRQ7w_F"
        },
        {
          name: "Annex 24 Energy Performance Contract Plus specifi…",
          link: "https://drive.google.com/uc?export=download&id=1JLBqXK-8WpKbE5fm9PfA1wDo3Vb4Wm-i"
        },
        {
          name: "Annex VI – Measurement and Verification Instructi…",

          link: "https://drive.google.com/uc?export=download&id=1R9zQSoD8gwPMPFI2Ku9J6hDvdpDF8K34"
        },

        {
          name: "ÖBEEF Investement Policy Guidelined and Standards…",

          link: "https://drive.google.com/uc?export=download&id=1mGYelerREzMR_xpXQUbndRPPtEQX2vjM"
        },

        {
          name: "Annex II - ÖBEEF standardized Forfaiting Agreemen…",

          link: "https://drive.google.com/uc?export=download&id=1vHKic-5TUy5CgGfbu5VRhjfYBHyciOc9"
        },

        {
          name: "Annex III – ÖBEEF standardized Maintenance Agreem…",

          link: "https://drive.google.com/uc?export=download&id=1hc1DPGsFZbSvTPdQ3gnKJArys6vxErpc"
        },

        {
          name: "Annex IV - Energy Performance Contract Instructio…",

          link: "https://drive.google.com/uc?export=download&id=1ce9N1oMG4_EN6bFBG7WuVC8zQuaXVzhM"
        },

        {
          name: "Annex V - Forfaiting Instructions",

          link: "https://drive.google.com/uc?export=download&id=1frjtwgwFlb_LFcmwhIKXMyC64iNgxnp8"
        },

        {
          name: "Annex I ÖBEEF standardized Energy Performance Con…",

          link: "https://drive.google.com/uc?export=download&id=16Xyh_Ch98MCotKrNAE3JTRksSd7eWHb7"
        },

        {
          name: "Annex I ÖBEEF standardized Energy Performance Con…",

          link: "https://drive.google.com/uc?export=download&id=1UW88FpFRUns5lH64KXorsZWMOGrPWUbX"
        },



      ]

    },
    {
      id: 2,
      country: 'Bulgaria',
      documents: [
        {
          name: "Bulgarian Building Energy Efficiency Facility",
          link: "https://drive.google.com/uc?export=download&id=1G2gftiXrxmeHeCfYnLS1NhVg_Gce_xcj"
        },
        {
          name: "Instructions-Forfaiting_EN",
          link: "https://drive.google.com/uc?export=download&id=17uGLMxsbvkDBmWFSTQMctwfHBne0ecJd"
        },
        {
          name: "Instructions-EPC_EN",
          link: "https://drive.google.com/uc?export=download&id=1PhUSc2myohr8M9g-sOUaxUbWyv0BXIAK"
        },
        {
          name: "Adapted-EPC-plus_EN ",
          link: "https://drive.google.com/uc?export=download&id=1KScyq2a6eGE_VGQSRob5LF8TSIWU0oKU"
        },
        {
          name: "Въведение",
          link: "https://drive.google.com/uc?export=download&id=1mmmWGR4IrkMYIgaoJsAMuD944FRCGrXz"
        },
        {
          name: "Adapted-EPC-plus_BG",
          link: "https://drive.google.com/uc?export=download&id=1zctKRsfQOBuVwswWiRC_zswksfQ7mIEM"
        },
        {
          name: "Instructions-Forfaiting_BG",
          link: "https://drive.google.com/uc?export=download&id=1ROeqiwN0tHFT1T-wyHP1eDqgUbVryKPI"
        },
        {
          name: "Instructions-EPC_BG",
          link: "https://drive.google.com/uc?export=download&id=1tyx6_Yu3eMkS1a9v-y3-t7hbTtUW7WSO"
        },
      ]
    },
    {
      id: 3,
      country: 'Poland',
      documents: [
        {
          name: "FORMULARZ RAPORTU Z ROZLICZENIA WIELKOŚCI ZUŻYCIA…",
          link: "https://drive.google.com/uc?export=download&id=1E52R8OwFBjM9vNk4xzp5au4ATnT6YXei"
        },
        {
          name: "Załącznik nr 6 – Powołanie Komisji Operacyjnej na…",
          link: "https://drive.google.com/uc?export=download&id=104n9DL-5Id00b5Ul2pNC2PvD75mzk-FZ"
        },
        {
          name: "Załącznik nr 5 – Protokół Odbioru Częściowego/Koń…",
          link: "https://drive.google.com/uc?export=download&id=1geSa9rVioYInimkp9vYcKg36G2-9PqzM"
        },
        {
          name: "Załącznik nr 4 – Powołanie Komisji Koordynacyjnej…",
          link: "https://drive.google.com/uc?export=download&id=1aW0L9NhRkDwVMMPCNDqaI6_LoSdCIXfq"
        },
        {
          name: "Załącznik nr 3 – Harmonogram Rzeczowo-Finansowy R…",
          link: "https://drive.google.com/uc?export=download&id=1QB-cv62NGu6_LpdbJvhROaPSthxe5PPE"
        },
        {
          name: "Załącznik nr 1 do umowy o poprawę efektywności en…",
          link: "https://drive.google.com/uc?export=download&id=1iCxyPwPwxkfCHxFqMNb5XN19syvEzN4N"
        },
        {
          name: "Umowa o poprawę efektywności energetycznej. Wersj…",
          link: "https://drive.google.com/uc?export=download&id=1dqOsVMHnf00bz2DwuWtBXJ7xVUCYx1L4"
        },
        {
          name: "Umowa o poprawę efektywności energetycznej. wersj…",
          link: "https://drive.google.com/uc?export=download&id=1St9aoKGgg2kY-5iRSLc-Bg9_3SzBZM9j"
        },
        {
          name: "Wzór umowy przeniesienia wierzytelności EPC+ NF p…",
          link: "https://drive.google.com/uc?export=download&id=1bbBko-sHA3xuV38Gzq2X_oBoJYRevgN0"
        },
        {
          name: "Technical guidelines",
          link: "https://drive.google.com/uc?export=download&id=1iKf3CI4Tq5VYgY6EjWkmu34SfBSSRcvm"
        },
        {
          name: "Priority Program",
          link: "https://drive.google.com/uc?export=download&id=1fLMUsnewvVciK7UHfqW5H186x-eHQ8Iw"
        },
        {
          name: "Forfaiting agreement template",
          link: "https://drive.google.com/uc?export=download&id=156O3ACHTgQTuKpwG3F3Izm2fPVH8853w"
        },
        {
          name: "Agreement on energy efficiency improvement (versi…",
          link: "https://drive.google.com/uc?export=download&id=1aqOpIjQ8DgxSIkBz9Qrif7S4cfqMZEfy"
        },
        {
          name: "Appendix 1 of the agreement on energy efficiency…",
          link: "https://drive.google.com/uc?export=download&id=1L0gr1J7ODQMFquS9EILtiDDx2vbRhUCt"
        },
      ]
    },
    {
      id: 4,
      country: 'Romania',
      documents: [
        {
          name: "ROMBEEF_Guidelines_EN - MMP",
          link: "https://drive.google.com/uc?export=download&id=1jbSwi9pBu2y06oo1zBt3uTbG3eKqzFBk"
        },
        {
          name: "AnnexVI_M&V (Romania - 2021)",
          link: "https://drive.google.com/uc?export=download&id=1aGMM1HdPrGCOm1_pz0ql0Z_1rANQcCsa"
        },
        {
          name: "AnnexV_Forfaiting_Instructions (Romania - 2021)",
          link: "https://drive.google.com/uc?export=download&id=1kE2JbQxm3HcYFlx3wI5x9jIqD-mwWioE"
        },
        {
          name: "AnnexIII_MaintenanceAgreement (Romania - 2021)",
          link: "https://drive.google.com/uc?export=download&id=1h4H32ML4GGQhOnImnvx63GX3a_Ur2rhi"
        },
        {
          name: "AnnexIV_EPC_Instructions (Romania - 2021)",
          link: "https://drive.google.com/uc?export=download&id=1tDISgQiltrukDk8EWiIKQTOzQky5xbR8"
        },
        {
          name: "AnnexII_ForfaitingAgreement (Romania - 2021)",
          link: "https://drive.google.com/uc?export=download&id=1mWzNcqaGV6b3o7jd8o9fSpugpzqq6p8u"
        },
        {
          name: "Annex 6 Energy Performance Contract Plus general…",
          link: "https://drive.google.com/uc?export=download&id=1WAdqm9LuaRxLyvKNy-lh6gL-Sq5j5VIp"
        },
        {
          name: "Annex 5 Energy Performance Contract Plus specific…",
          link: "https://drive.google.com/uc?export=download&id=19V2DTcc6It-18b9LIDBhQyATkqXU4DFq"
        },
        {
          name: "ROMBEEF_Guidelines_RO",
          link: "https://drive.google.com/uc?export=download&id=1TLbqWgHut31Lq9DlZMIOQsFh5NWqdUGV"
        },
        {
          name: "Anexa VI - M&V (Romania - 2021)",
          link: "https://drive.google.com/uc?export=download&id=1VBPOvYsksrELOvVEWLSFID0guvQhujpH"
        },
        {
          name: "Anexa V - Instrucțiuni de cesiune (Romania - 2021…",
          link: "https://drive.google.com/uc?export=download&id=1C5n6kwV-6CRC6Mh3S6-XkF7bIjaLhCiB"
        },
        {
          name: "Anexa ll - Acordul de cesiune (Romania - 2021)",
          link: "https://drive.google.com/uc?export=download&id=1UBKZnoFDH798i84lQp8_ywwr7Rv-dqlF"
        },
        {
          name: "Anexa III Acordul de întreținere (Romania - 2021)…",
          link: "https://drive.google.com/uc?export=download&id=151n5WPCobCHWPN21njz-eq3g_vh3hpwP"
        },
        {
          name: "Anexa IV Instrucțiuni EPC (Romania - 2021)",
          link: "https://drive.google.com/uc?export=download&id=1ZsqK5MJ-te4m7lUjEjpq3pnddoR0N7O0"
        },
        {
          name: "Annex 8 Energy Performance Contract Plus general…",
          link: "https://drive.google.com/uc?export=download&id=1FWSvwB-QhARIYNdK-f74rT2hGimwlDg6"
        },
        {
          name: "Annex 7 Energy Performance Contract Plus specific…",
          link: "https://drive.google.com/uc?export=download&id=12ihA7Znxz8pGYil5_HWgm2DqbgGMtmcU"
        },

      ]
    },
    {
      id: 5,
      country: 'Slovakia',

      documents: [
        {
          name: "SKBEEF Investement Policy Guidelined and Standard…",
          link: "https://drive.google.com/uc?export=download&id=15cuScs5Ivscw_YUwUy-g2BiPAv-AHr4y"
        },
        {
          name: "Annex VI – Measurement and Verification Instructi…",
          link: "https://drive.google.com/uc?export=download&id=1iE-MQmexKvYnIn7zlvjBHOJkwztL-Pz1"
        },
        {
          name: "Annex IV - Energy Performance Contract Instructio…",
          link: "https://drive.google.com/uc?export=download&id=1OAl9uRnjf9FZDAN75k6WJcyA4z2U2MMr"
        },
        {
          name: "Annex III – SKBEEF standardized Maintenance Agree…",
          link: "https://drive.google.com/uc?export=download&id=1q8KAP6JR-sfS6jm9bP44g6GOfUPl_-tB"
        },
        {
          name: "Annex II - SKBEEF standardized Forfaiting Agreeme…",
          link: "https://drive.google.com/uc?export=download&id=1IPtImUqsy6HZ0BPdm4O0eLoyVLypDaMY"
        },
        {
          name: "Annex V - Forfaiting Instructions",
          link: "https://drive.google.com/uc?export=download&id=1ebmvLiQRz0EO5iTHKVIrJjph4_1wt70E"
        },
        {
          name: "General terms SKBEEF standardized Energy Performance Co…",
          link: "https://drive.google.com/uc?export=download&id=1_nd6tb-nyUDsCR9ROhyaw001aq9Gae9c"
        },
        {
          name: "Annex I SKBEEF standardized Energy Performance Co…",
          link: "https://drive.google.com/uc?export=download&id=1cknPbwl8Au5uX5ytrPCIeyKMU5-O5B2C"
        },
        {
          name: "Príloha VI Pokynmi na Meranie a overovanie",
          link: "https://drive.google.com/uc?export=download&id=1dnHaNzqDGEeALdAvEmoZZXJANk-unZUK"
        },
        {
          name: "SKBEEF Smernice a štandardy investičnej politiky.…",
          link: "https://drive.google.com/uc?export=download&id=1-rLniU-JQxCKZnDrxoPprwc7P__pmQB_"
        },
        {
          name: "Príloha V Pokyny týkajúce sa forfaitingu",
          link: "https://drive.google.com/uc?export=download&id=1aPAm7-NZ8JKmJ0PcsuRw8tcNpNqZvHcA"
        },
        {
          name: "Príloha IV Zmluvy o energetickej efektívnosti pok…",
          link: "https://drive.google.com/uc?export=download&id=1oBaejD543l6chjXyZcKs0y0yS7QIrhXU"
        },
        {
          name: "Príloha III Zmluva o údržbe",
          link: "https://drive.google.com/uc?export=download&id=1Ys-MVR4ZIMVsFOuUcGhcWBXVchN8kny7"
        },
        {
          name: "Príloha II Forfaitingovej Zmluvy",
          link: "https://drive.google.com/uc?export=download&id=11GOn-vLbLSfdUwjXMcEp7dgoQqi8OVpZ"
        },
        {
          name: "Príloha I - Štandardizovaná zmluva SKBEEF o energ…",
          link: "https://drive.google.com/uc?export=download&id=1yYkB_eupH4HebGEzxcvWTH9gKA-rQUO6"
        },
        {
          name: "Príloha I - VŠEOBECNÉ PODMIENKY SKBEEF o energ…",
          link: "https://drive.google.com/uc?export=download&id=1HYD2KZ7jYCANTFJ_3OD8DhGszR_jmwgR"
        },

      ]


    }

  ];

  return (
    <div>
      <NavContainer />
      <CenteredDiv>
        <Typography variant="h4">Investment policy guidelines and standards</Typography>
      </CenteredDiv>
      <Divider style={{ margin: '1rem 0' }} />

      <GuidelinesContainer>

        {guidelines.map((guideline) => (
          <GuidelineItem key={guideline.id}>
            <GuidelineTitle>{guideline.country}</GuidelineTitle>
            {guideline.documents?.map((document) => (
              <GuidelineDescription>
                <StyledLink href={document.link} target="_blank" rel="noopener noreferrer">
                  {document.name}
                </StyledLink>
              </GuidelineDescription>
            ))}
          </GuidelineItem>
        ))}
      </GuidelinesContainer>
    </div>

  );
};

export default GuidelinesPage;
