export const at = {
  building: {
    saving: require('./at/building/saving.json'),
  },
  industry: {
    saving: require('./at/industry/saving.json'),
  },
};

export const bg = {
  building: {
    saving: require('./bg/building/saving.json'),
  },
  industry: {
    saving: require('./bg/industry/saving.json'),
  },
};

export const eu = {
  building: {
    saving: require('./eu/building/saving.json'),
  },
  industry: {
    saving: require('./eu/industry/saving.json'),
  },
};

export const lv = {
  building: {
    saving: require('./lv/building/saving.json'),
  },
  industry: {
    saving: require('./lv/industry/saving.json'),
  },
};

export const pl = {
  building: {
    saving: require('./pl/building/saving.json'),
  },
  industry: {
    saving: require('./pl/industry/saving.json'),
  },
};

export const ro = {
  building: {
    saving: require('./ro/building/saving.json'),
  },
  industry: {
    saving: require('./ro/industry/saving.json'),
  },
};

export const sk = {
  building: {
    saving: require('./sk/building/saving.json'),
  },
  industry: {
    saving: require('./sk/industry/saving.json'),
  },
};
