export const countries = [
  { value: 7, label: 'Albania' },
  { value: 8, label: 'Andorra' },
  { value: 9, label: 'Armenia' },
  { value: 6, label: 'Austria' },
  { value: 10, label: 'Belarus' },
  { value: 11, label: 'Belgium' },
  { value: 12, label: 'Bosnia and Herzegovina' },
  { value: 3, label: 'Bulgaria' },
  { value: 13, label: 'Croatia' },
  { value: 14, label: 'Cyprus' },
  { value: 15, label: 'Czech Republic' },
  { value: 16, label: 'Denmark' },
  { value: 17, label: 'Estonia' },
  { value: 18, label: 'Finland' },
  { value: 19, label: 'France' },
  { value: 20, label: 'Georgia' },
  { value: 21, label: 'Germany' },
  { value: 22, label: 'Greece' },
  { value: 23, label: 'Hungary' },
  { value: 24, label: 'Ireland' },
  { value: 25, label: 'Italy' },
  { value: 26, label: 'Kosovo' },
  { value: 1, label: 'Latvia' },
  { value: 27, label: 'Liechtenstein' },
  { value: 28, label: 'Lithuania' },
  { value: 29, label: 'Luxembourg' },
  { value: 30, label: 'Malta' },
  { value: 31, label: 'Moldova' },
  { value: 32, label: 'Monaco' },
  { value: 33, label: 'Montenegro' },
  { value: 34, label: 'Netherlands' },
  { value: 35, label: 'North Macedonia' },
  { value: 36, label: 'Norway' },
  { value: 2, label: 'Poland' },
  { value: 37, label: 'Portugal' },
  { value: 4, label: 'Romania' },
  { value: 38, label: 'Russia' },
  { value: 39, label: 'San Marino' },
  { value: 40, label: 'Serbia' },
  { value: 5, label: 'Slovakia' },
  { value: 41, label: 'Slovenia' },
  { value: 42, label: 'Spain' },
  { value: 43, label: 'Sweden' },
  { value: 44, label: 'Switzerland' },
  { value: 45, label: 'Turkey' },
  { value: 46, label: 'Ukraine' },
  { value: 47, label: 'United Kingdom' },
  { value: 48, label: 'Vatican City' },
];

export const countriesMap = {
  7: 'Albania',
  8: 'Andorra',
  9: 'Armenia',
  6: 'Austria',
  10: 'Belarus',
  11: 'Belgium',
  12: 'Bosnia and Herzegovina',
  3: 'Bulgaria',
  13: 'Croatia',
  14: 'Cyprus',
  15: 'Czech Republic',
  16: 'Denmark',
  17: 'Estonia',
  18: 'Finland',
  19: 'France',
  20: 'Georgia',
  21: 'Germany',
  22: 'Greece',
  23: 'Hungary',
  24: 'Ireland',
  25: 'Italy',
  26: 'Kosovo',
  1: 'Latvia',
  27: 'Liechtenstein',
  28: 'Lithuania',
  29: 'Luxembourg',
  30: 'Malta',
  31: 'Moldova',
  32: 'Monaco',
  33: 'Montenegro',
  34: 'Netherlands',
  35: 'North Macedonia',
  36: 'Norway',
  2: 'Poland',
  37: 'Portugal',
  4: 'Romania',
  38: 'Russia',
  39: 'San Marino',
  40: 'Serbia',
  5: 'Slovakia',
  41: 'Slovenia',
  42: 'Spain',
  43: 'Sweden',
  44: 'Switzerland',
  45: 'Turkey',
  46: 'Ukraine',
  47: 'United Kingdom',
  48: 'Vatican City',
};

export const countriesLabels = [
  'Albania',
  'Andorra',
  'Armenia',
  'Austria',
  'Belarus',
  'Belgium',
  'Bosnia and Herzegovina',
  'Bulgaria',
  'Croatia',
  'Cyprus',
  'Czech Republic',
  'Denmark',
  'Estonia',
  'Finland',
  'France',
  'Georgia',
  'Germany',
  'Greece',
  'Hungary',
  'Ireland',
  'Italy',
  'Kosovo',
  'Latvia',
  'Liechtenstein',
  'Lithuania',
  'Luxembourg',
  'Malta',
  'Moldova',
  'Monaco',
  'Montenegro',
  'Netherlands',
  'North Macedonia',
  'Norway',
  'Poland',
  'Portugal',
  'Romania',
  'Russia',
  'San Marino',
  'Serbia',
  'Slovakia',
  'Slovenia',
  'Spain',
  'Sweden',
  'Switzerland',
  'Turkey',
  'Ukraine',
  'United Kingdom',
  'Vatican City',
];

export const countryCodesCountries = {
  AT: 'Austria',
  BG: 'Bulgaria',
  LV: 'Latvia',
  PL: 'Poland',
  RO: 'Romania',
  SK: 'Slovakia',
  EU: 'Europe',
};

export const countriesCountryCodes = {
  Austria: 'AT',
  Bulgaria: 'BG',
  England: 'EN',
  Latvia: 'LV',
  Poland: 'PL',
  Romania: 'RO',
  Slovakia: 'SK',
  Europe: 'EU',
};

export const supportedCountries = [
  'Austria',
  'Bulgaria',
  'Latvia',
  'Poland',
  'Romania',
  'Slovakia',
];

export const supportedCountriesOptions = [
  { value: 'eu', label: 'EU - 27 & UK' },
  { value: 'at', label: 'Austria' },
  { value: 'bg', label: 'Bulgaria' },
  { value: 'lv', label: 'Latvia' },
  { value: 'pl', label: 'Poland' },
  { value: 'ro', label: 'Romania' },
  { value: 'sk', label: 'Slovakia' },
];

export function labelFromCC(cc, back) {
  if (!cc || !(cc in countryCodesCountries)) {
    return back;
  }

  return countryCodesCountries[cc];
}
