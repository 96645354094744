export default [
    'EUR',
    'ALL',
    'AMD',
    'BYN',
    'BAM',
    'BGN',
    'HRK',
    'CZK',
    'DKK',
    'GEL',
    'HUF',
    'ISK',
    'CHF',
    'MDL',
    'MKD',
    'NOK',
    'PLN',
    'RON',
    'RSD',
    'SEK',
    'RUB',
    'TRY',
    'UAH',
    'GBP',
];